import React from 'react'

const Grid = () => {
  return (
    <div className="grid-halves wf-section">
  <div className="bg-violet border-right">
    <div className="column-padding centered">
      <div className="text-2xl">
        Understand processes & <br />
        Your customers.
      </div>
    </div>
  </div>
  <div className="bg-pink">
    <div className="column-padding centered">
      <div className="text-2xl">
        Run successful operations <br />
        through streamlined solutions!
      </div>
    </div>
  </div>
</div>

  )
}

export default Grid