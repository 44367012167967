import React from 'react'
import "../components/ContactUs.css"
import Footer from './Footer'
import NavigationBar from './NavigationBar'
import "../components/ContactUs.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from "yup"

const initialValues = {
  name: "",
  email: "",
  business: "",
  request: "",
  message: ""
}

let schema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name!"),
  email: Yup.string().email().required("Please enter your email!"),
  business: Yup.string().min(4).max(30).required("Please enter your business name!"),
  request: Yup.string().required("Please choose your request!"),
  message: Yup.string().min(4).max(30),

})

const ContactDemo = () => {

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values, action) => {
      console.log(values)
      if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
        console.log("sending response");
        fetch('https://api.seoux.co/contact', { method: 'POST', body: JSON.stringify(values), headers: { "Content-Type": "application/json" } }).then((data) => {
          console.log(data);
          toast.success("Form submitted.");
          action.resetForm();
        });
      } else {
        toast.error("Please fill the details.", {
          autoClose: 2000,
          fontFamily: 'PSans',
        });
      }

    }
  })
  console.log(errors)

  return (
    <>
      <NavigationBar />
      <div className="container" >
        <div className="row">
          <h1 style={{ textAlign: "center", }}>Let's chat.</h1>
        </div>
        <div className="row" style={{ marginBottom: "64px" }}>
          <h1 style={{ textAlign: "center", marginTop: "60px", fontSize: "92px", fontWeight: "400" }}>Let's chat.</h1>

          <h4 style={{ textAlign: "center" }}>Are you ready to make something memorable?<br />We're ready to help.</h4>
        </div>
        <div className="row input-container" style={{ paddingBottom: "100px" }}>
          <form onSubmit={handleSubmit}>
            <input className="styled-input" type="text" name="name" placeholder="Full Name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
            {errors.name && touched.name ? <p style={{ color: "red" }}>{errors.name}</p> : null}
            <input className="styled-input" type="email" name="email" placeholder="E-mail" value={values.email} onChange={handleChange} onBlur={handleBlur} />
            {errors.email && touched.email ? <p style={{ color: "red" }}>{errors.email}</p> : null}

            <input className="styled-input" type="text" name="business" placeholder="Business" value={values.business} onChange={handleChange} onBlur={handleBlur} />
            {errors.business && touched.business ? <p style={{ color: "red" }}>{errors.business}</p> : null}

            <select name="request" value={values.request} onChange={handleChange} onBlur={handleBlur}>
              <option >Choose a request*</option>
              <option >Custom Software Development</option>
              <option >Website Development</option>
              <option >Web App Development</option>
              <option>Website Maintenance</option>
              <option>Mobile App Development</option>
              <option>End-to-End Platform Development</option>
            </select>
            {errors.request && touched.request ? <p style={{ color: "red" }}>{errors.request}</p> : null}

            <textarea rows="5" cols="20" name="message" placeholder="Message" value={values.message} onChange={handleChange} onBlur={handleBlur}>
            </textarea>
            {errors.message && touched.message ? <p style={{ color: "red" }}>{errors.message}</p> : null}

            <button disabled={isSubmitting} type="submit" className="button-primary">
              Send Message
              {
                isSubmitting
                &&
                <div class="spinner-border text-light" role="status"></div>
              }
            </button>
            <ToastContainer
              style={{ fontFamily: "'PSans',sans-serif" }}
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              type="success"
            />

          </form>
        </div>
      </div>

      <Footer />
    </>

  )
}

export default ContactDemo