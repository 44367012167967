import React from 'react'
import cx from "./PageNotFound.module.css"
import pagenotfound from "../assets/pagenotfound.webp"

const PageNotFound = () => {
  return (
    <>
    <div className={cx.divbody}>
    <figure>
    <img className={cx.test} src={pagenotfound} alt="Page not found" />
  </figure>
  <h1 className={cx.pageheading}>Page not found</h1>
  <hr className={cx.line} />
  <p className={cx.para}>The thing you were looking for doesn't exist.</p>
  <nav>
    <ul>
      <li>
        <a className={cx.anchor} href="/">Go home?</a>
      </li>
    </ul>
  </nav>
  </div>
</>

  )
}

export default PageNotFound