import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import PageNotFound from "./components/PageNotFound";
import CaseStudy from "./components/CaseStudy";
import CaseStudyHeader from "./components/CaseStudyHeader";
import ContactDemo from "./components/ContactDemo";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/contact" element={<ContactDemo />} />
				<Route path="/case-studies" element={<CaseStudy />} />
				<Route path="/case-studies-details/:id" element={<CaseStudyHeader />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</Router>
	);
}

export default App;
