import React from 'react'
import headerhero from "../assets/heroheader2.png"
import RadioButton from './RadioButton'

const Banner = () => {
  return (
    <div className="wf-section">
      <div className="grid-halves" style={{ minHeight: '80vh' }}>
        <div className="bg-pink border-right">
          <div className="column-padding">
            <div className="tablet-centered">
              <div className="content-grid home-hero">
                <h1 className='craft-text'>
                  We craft <span className="line-break-fixed">experiences.</span>
                </h1>
                <p className="section-sub-head">
                  SEOUX designs and builds to inspire people to think, feel, or do.
                  We build next-gen customized solutions with last mile functionality.
                </p>
                <RadioButton title="Learn More" href={"/case-studies"} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-yellow-dark">
          <div className="column-padding centered">
            <div className="callout-wrap">
              <img
                src={headerhero}
                loading="lazy"
                width={605}
                alt=""
                className="callout-image creator"
                style={{
                  willChange: "transform",
                  transform:
                    "translate3d(0px, 2.15316vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Banner
