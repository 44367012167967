import React from 'react'
import SEOUX04C from "../assets/SEOUX04C.webp"

const BannerAmount = () => {
  return (
    <div className="section income-earned-section wf-section p-0">
    <div className="container">
      <div className="earned-income-intro" 
      // style={{ marginTop: '7rem' }}
      >
        <h3 className="earned-income-number">
          {/* <span id="payout-usd" className="counter"> */}
            16,00,000
          {/* </span> */}
        </h3>
        <div className="text-lg">
          Total hours of expertise.
        </div>
      </div>
    </div>
    <img
      src={SEOUX04C}
      loading="lazy"
      alt=""
      className="earned-income-illustration"
    />
  </div>
  
  )
}

export default BannerAmount