import React, { useState, useEffect } from 'react'
import "./CaseStudyHeader.css"
import Footer from './Footer'
import NavigationBar from './NavigationBar';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import SubFooter from './SubFooter';

const CaseStudyHeader = () => {
  let {id} = useParams();
  const [data, setData] = useState();
  const [items, setItems] = useState({header: '', image: undefined, date: ''});

  useEffect(() => {
    if (id){
    fetch(require(`../blog/${id}.md`)).then(res => res.text()).then(text => {
      const header = text.split("\n")[0];
      const image = text.split("\n")[1];
      const date = text.split("\n")[2];
      setItems({
        header,
        image,
        date
      });
      text = text.split('\n');
      text.splice(0,3);
      setData(text.join('\n'));

    })};
  }, [id]);

  return (
    <>
      <NavigationBar />
      <div
        style={{
          backgroundImage:
            `url(${items.image})`
        }}
        className="blog-hero wf-section"
      >
        <div
          className="blog-hero-darken"
          style={{ willChange: "opacity", opacity: 0 }}
        />
      </div>
      <div className='container'>
        <div className='blog-content-wrap'>
          <div className="blog-content-intro-grid">
            <h1 className="title-case" >
              {items.header}
            </h1>
            <ReactMarkdown children={data} />
          </div>
        </div>
      </div>
      <SubFooter/>
      <Footer/>

    </>


  )
}

export default CaseStudyHeader