import React from "react";

const Para = (props) => {
  return (
    <div className="section intro-section wf-section">
    <div className="container">
      <div className="section-intro">
        <h2 className="section-title">{props.title}</h2>
        <p className="text-md">{props.paragraph}
         
        </p>
        {/* <a
          href="/features"
          className="underline-link w-inline-block"
        >
          <div className="underline-link-content">
            <div className="underline-link-text">Explore Features</div>
            <div className="underline-link-arrow">→</div>
          </div>
          <div style={{ width: 0 }} className="underline-link-line" />
        </a> */}
      </div>
    </div>
  </div>
  
  );
};

export default Para;
