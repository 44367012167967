export const arr =[
    {
        url:"/case-studies-details/developed-a-customer-focused",
        img:"/blog/medical.png",
        date:"October 30,2022",
        title:"Developed a customer-focused web application for a US-based translation company.",
        content:"CQ Fluency is an American communication and translation service provider that specializes in cultural adaptation for the healthcare and life science industries.",
},
{
    url:"/case-studies-details/website-redevelopment",
    img:"/blog/website-dev.png",
    date:"October 30,2022",
    title:"Website Redevelopment and Redesign for a Leading Logistics Company in the US.",
    content:"Kenco is a top-ranked third-party logistics (3PL) company in the United States. We provide integrated logistics solutions that include distribution and eCommerce fulfillment, comprehensive",
},
{
    url:"/case-studies-details/modernization-of-a-popular",
    img:"/blog/popular.png",
    date:"October 30,2022",
    title:"Transformed eLearning platform for medical students preparing for the USMLE exam.",
    content:"Dr. Jason Ryan, an MD and Cardiologist at the University of Connecticut Medical Center, is an award-winning educator and has been teaching as a medical school professor for years. His passion for medical science and teaching led him to create Boards & Beyond.",
},
{
    url:"/case-studies-details/kids-activity",
    img:"/blog/kids.png",
    date:"October 30,2022",
    title:"Developed seamless digital experience for fun-filled kid activities around Atlanta city.",
    content:"Nothing can beat the happiness you feel spending quality time with your family. Even your kids love it when you take part in different fun-filled activities with them. Kidscitypass brings you the opportunity to do so easily.",
},
{
    url:"/case-studies-details/saas",
    img:"/blog/saas.png",
    date:"October 30,2022",
    title:"Engineered a highly sophisticated SaaS application for a global Pharmaceutical giant.",
    content:"The client is one of the world leaders in the development, supply and technical support of formulated film coating systems. ",
},
{
    url:"/case-studies-details/global",
    img:"/blog/global.png",
    date:"October 30,2022",
    title:"Platform modernization for a Nasdaq-listed, global transcription company.",
    content:"The client is a leading, global service provider of secure, AI-driven digital voice/video capture technology and transcription services. ",
},



]