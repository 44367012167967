import React from 'react'
import loggo from "../assets/loggo.png";

const NavigationBar = () => {
    return (

        <div className="nav-container w-container">
            <a href='/'>
                <img src={loggo} alt="seoux" height="42px" style={{ marginRight: 96 }} /></a>
            <nav role="navigation" className="nav-menu w-nav-menu">
                {/* <div>
                    <a
                        href="/features"
                        className="nav-link w-inline-block"
                    >
                        <div className="nav-link-text">Features</div>
                        <div className="nav-link-underline" style={{ width: 0 }} />
                    </a>
                    <a
                        href="/pricing"
                        className="nav-link w-inline-block"
                    >
                        <div className="nav-link-text">Pricing</div>
                        <div className="nav-link-underline" style={{ width: 0 }} />
                    </a>
                    <a
                        href="/university"
                        className="nav-link w-inline-block"
                    >
                        <div className="nav-link-text">University</div>
                        <div className="nav-link-underline" style={{ width: 0 }} />
                    </a>
                    <a
                        // id="blog-link"
                        href="/case-studies"
                        className="nav-link w-inline-block"
                    >
                        <div className="nav-link-text">Case Study</div>
                        <div className="nav-link-underline" style={{ width: 0 }} />
                    </a>
                    <a
                        id="discover"
                        href="/discover"
                        className="nav-link w-inline-block"
                    >
                        <div className="nav-link-text">Discover</div>
                        <div className="nav-link-underline" style={{ width: 0 }} />
                    </a>
                </div> */}
            </nav>
            <div className="nav-menu-secondary">
                {/* <a
                    href="/contact"
                    className="nav-link log-in w-inline-block"
                >
                    <div>Login</div>
                </a> */}
                <a
                    href="/contact"
                    className="nav-link sign-up w-inline-block"
                >
                    <div>Request a Quote</div>
                </a>
            </div>
            {/* <div
                className="menu-button w-nav-button"
                style={{ WebkitUserSelect: "text" }}
                aria-label="menu"
                role="button"
                tabIndex={0}
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
            >
                <div className="menu-icon-wrap">
                    <div className="menu-line top" />
                    <div className="menu-line bottom" />
                </div>
            </div> */}

        </div>

    )
}

export default NavigationBar
