import React, { useEffect } from 'react';
import NavigationBar from "./NavigationBar";
import Banner from "./Banner";
import Cards from "./Cards";
import TickerTape from "./TickerTape";
import Grid from "./Grid";
import Para from "./Para";
import SellAny from "./SellAny";
import TextBannner from "./TextBannner";
import BannerAmount from "./BannerAmount";
import Footer from "./Footer";
import SubFooter from "./SubFooter";
import sellany1 from "../assets/sellany1.svg"
import ReactGA from "react-ga"

ReactGA.initialize('G-J72N91FE16');

const Home = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])
    return (
        <>
            <NavigationBar />
            <div className="main-start">
                <Banner />
                <TickerTape />
                <Cards />
                {/* <Track /> */}
                <Grid />
                <Para
                    title="Who are we?"
                    paragraph=" We’re a team of highly skilled individuals with diverse creative backgrounds. We’re visionaries of tomorrow and excited about today’s challenges. We’re big believers in working side by side with our clients. We ask the right questions and deliver the optimal interactive solution."
                />
                <SellAny
                    img={sellany1}
                    heading="We make everything"
                    content=" Website, Custom Software, ERP, Mobile Apps, you name it! &nbsp;SEOUX was created to help your brand with all kinds of technology solutions."
                    list="Analytics and Insights,
                    Cyber Security,
                    Automation & AI,
                    IOT & Digital Engineering,
                    Cloud,
                    ERP/CMS,
And much more!,"
                />
                <TextBannner
                    text=" The SEOUX team is meticulous, thorough &amp; energetic. I was delighted with their innovative approach and use of technology. We have been working with SEOUX for many years successfully. They are really fun to do business with. I highly recommend SEOUX."
                />
                
                <BannerAmount />
                {/* <Para
                    title="Have a look what our clients say"
                    paragraph=" We take pride in developing enterprise web services and small business web solutions to meet your specific needs."
                /> */}
                {/* <Testimonial /> */}
                <SubFooter />
                <Footer />
            </div>
        </>
    )
}

export default Home;
