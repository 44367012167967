import React from 'react'

const SellAny = (props) => {
  const listItems = props.list?.split(",");
  return (
    <div className="grid-halves wf-section">
      <div className="bg-teal border-right no-overflow">
        <img
          src={props.img}
          loading="lazy"
          alt=""
          className="callout-image sell-anything"

        />
      </div>
      <div>
        <div className="column-padding">
          <div className="content-grid">
            <h3 className="text-2xl">{props.heading}</h3>
            <div className="text-md">{props.content}
            </div>
            <ul className="list-grid">
              {
                listItems && listItems.map(item => <li key={item} className='arrow-list-item'>{item}</li>)
              }
            </ul>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SellAny