import React from 'react'
import RadioButton from './RadioButton'

const SubFooter = () => {
  return (
    <div className="section">
    <div className="container">
      <div className="section-intro cta">
        <h2 className="section-title">
          We'd love to chat about {" "}
          <span className="line-break">what we could do together.</span>
        </h2>
        <RadioButton title="Get in Touch"/>
        {/* <a
  id="w-node-_1b6eb762-1807-5a52-2bb7-99fadfccce52-8279f859"
  
  href="/contact"
  className="shadow-button-wrap w-inline-block"
  style={{
    transform:
      "translate3d(0rem, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d"
  }}
>
  <div className="shadow-button-text">Get in Touch</div>
  <div
    className="shadow-button-shadow _2 red"
    style={{
      transform:
        "translate3d(0rem, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d"
    }}
  />
  <div
    className="shadow-button-shadow yellow"
    style={{
      transform:
        "translate3d(0rem, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d"
    }}
  />
</a> */}

      </div>
    </div>
  </div>
  
  )
}

export default SubFooter