import React from "react";

const TickerTape = () => {
  return (
    <div className="hwrap"><div className="hmove">
      <div className="hitem">Build to grow •</div>
      <div className="hitem">Build to inspire •</div>
      <div className="hitem">Build to innovate •</div>
      <div className="hitem">Build to scale •</div>
      <div className="hitem">Build to experience •</div>
      <div className="hitem">Build to grow •</div>
      <div className="hitem">Build to inspire •</div>
      <div className="hitem">Build to innovate •</div>
      <div className="hitem">Build to scale •</div>
      <div className="hitem">Build to experience •</div>
      <div className="hitem">Build to grow •</div>
      <div className="hitem">Build to inspire •</div>
      <div className="hitem">Build to innovate •</div>
      <div className="hitem">Build to scale •</div>
      <div className="hitem">Build to experience •</div>
      <div className="hitem">Build to grow •</div>
      <div className="hitem">Build to inspire •</div>
      <div className="hitem">Build to innovate •</div>
      <div className="hitem">Build to scale •</div>
      <div className="hitem">Build to experience •</div>
      <div className="hitem">Build to grow •</div>
      <div className="hitem">Build to inspire •</div>
      <div className="hitem">Build to innovate •</div>
      <div className="hitem">Build to scale •</div>
      <div className="hitem">Build to experience •</div>

    </div>
    </div>

  );
};

export default TickerTape;
