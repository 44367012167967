import React, { useState } from 'react'
import cx from "./RadioButton.module.css";

const DEFAULT_TRANSFORM = 'translate3d(0rem, 0rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
const DEFAULT_STYLES = { transform: DEFAULT_TRANSFORM };
const STEPS = 16;

const RadioButton = (props) => {
    const [st, setSt] = useState([DEFAULT_STYLES, DEFAULT_STYLES, DEFAULT_STYLES]);

    const handleMouseEnter = () => {
        let step = 0;
        const intervalId = setInterval(() => {
            step = step + 1;
            setSt([{
                transform: `translate3d(${-1 * ((step + 1) / STEPS)}rem, ${-1 * ((step + 1) / STEPS)}rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`
            }, {
                transform: `translate3d(${(step + 1) / STEPS}rem, ${(step + 1) / STEPS}rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                border: '2px solid #000'
            }, {
                transform: `translate3d(${0.5 * ((step + 1) / STEPS)}rem, ${0.5 * ((step + 1) / STEPS)}rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                border: '2px solid #000'
            }]);
            if (step === STEPS) {
                clearInterval(intervalId);
            }
        }, 128 / STEPS);
    }

    const handleMouseLeave = () => {
        setSt([DEFAULT_STYLES, DEFAULT_STYLES, DEFAULT_STYLES]);
    }

    return (
        <div>
            <a
                href={props.href || "/contact"}
                className="shadow-button-wrap w-inline-block"
                style={st[0]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className={cx.btntextt}>{props.title}</div>
                <div
                    className={cx.btnshadow2} style={st[1]}
                />
                <div
                    className={cx.btnshadow} style={st[2]}
                />
            </a>

        </div>
    )
}

export default RadioButton