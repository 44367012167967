import React from 'react'
import textb2 from "../assets/textb2.svg"

const TextBannner = (props) => {
  return (
    <div className="grid-halves bg-pink wf-section">
    <div>
      <div className="column-padding creator">
        <div className="content-grid">
          <h2 className="text-lg">{props.text}
          </h2>
          <div>
            <strong>Andrew Werth</strong>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div className="column-padding">
        <div
          className="callout-wrap"
        >
          <img
            src="testimonial.png"
            loading="lazy"
            width="496.5"
            sizes="100vw"
            alt=""
            className="callout-image creator"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, -2.82912vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
          />
          <img
            src={textb2}
            loading="lazy"
            alt=""
            className="sticker home-03"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, 2.3576vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d"
            }}
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default TextBannner