import React, { useState } from 'react'
import { omit } from "lodash"
import "../components/ContactUs.css"
import Footer from './Footer'
import NavigationBar from './NavigationBar'
import "../components/ContactUs.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ContactUs = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const validate = (event, name, value) => {

    switch (name) {
      case 'name':
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: 'Name atleast have 3 letters'
          })
        } else {
          // set the error state empty or remove the error for username input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "name");
          setErrors(newObj);

        }
        break;

      case 'email':
        if (
          !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
        ) {
          setErrors({
            ...errors,
            email: 'Enter a valid email address'
          })
        } else {

          let newObj = omit(errors, "email");
          setErrors(newObj);

        }
        break;

      default:
        break;
    }
  }
  const handleChange = (event) => {
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);

    setValues({
      ...values,
      [name]: val,
    })
  }

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    console.log({ errors });
    console.log({ values });
    if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
      console.log("sending response");
      fetch('https://api.seoux.co/contact', { method: 'POST', body: JSON.stringify(values), headers: { "Content-Type": "application/json" } }).then((data) => {
        console.log(data);
        toast.success(" Form submitted")
        setTimeout(() => {
          window.location.reload()

        }, 6000)

      })
    } else {
      toast.error("Please fill the details.", {
        autoClose: 2000,
        fontFamily: 'PSans',
      });
    }
  }

  return (
    <>
      <NavigationBar />
      <div className="container" >
        <div className="row">
          <h1 style={{ textAlign: "center", }}>Let's chat.</h1>
        </div>
        <div className="row" style={{ marginBottom: "64px" }}>
        <h1 style={{ textAlign: "center",marginTop:"60px",fontSize:"92px",fontWeight:"400" }}>Let's chat.</h1>

          <h4 style={{ textAlign: "center" }}>Are you ready to make something memorable?<br />We're ready to help.</h4>
        </div>
        <div className="row input-container" style={{paddingBottom:"100px"}}>
          <form onSubmit={handleSubmit}>
            <input className="styled-input" type="text" minLength='4' required name="name" placeholder="Full Name" onChange={handleChange} />
            {
              errors.name && <h3 style={{ color: "red", fontSize: "14px" }}>{errors.name}</h3>
            }
            <input className="styled-input" type="email" name="email" placeholder="E-mail" onChange={handleChange} />
            {
              errors.email && <h5>{errors.email}</h5>
            }
            <input className="styled-input" type="text" name="business" placeholder="Business" onChange={handleChange} />
            <select onChange={handleChange} name="request">
              <option >Choose a request*</option>
              <option >Custom Software Development</option>
              <option >Website Development</option>
              <option >Web App Development</option>
              <option>Website Maintenance</option>
              <option>Mobile App Development</option>
              <option>End-to-End Platform Development</option>
            </select>
            <textarea rows="5" cols="20" name="message" placeholder="Message" onChange={handleChange}>
            </textarea>

            <button onClick={handleSubmit} type="submit" value="Submit" className="button-primary">Send Message</button>
            <ToastContainer
              style={{ fontFamily: "'PSans',sans-serif" }}
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              type="success"
            />

          </form>
        </div>
      </div>

      <Footer />
    </>

  )
}

export default ContactUs