import React from 'react'
import "./CaseStudy.css"
import Footer from './Footer'
import SubFooter from './SubFooter'
import NavigationBar from './NavigationBar'
import study1 from "../assets/study1.svg"
import study2 from "../assets/study2.svg"
import study3 from "../assets/study3.svg"
import study4 from "../assets/study4.svg"
import study5 from "../assets/study5.svg"
import { arr } from '../data'


const CaseStudy = () => {
  return (
    <div className='page-wrap'>
      <NavigationBar />
      <div
        className="section bg-pink wf-section"
      >
        <div className="container">
          <div className="section-intro">
            <h1 className="section-tagline">Case Studies</h1>
            <h1>The latest news and resources</h1>
            <p className="text-md">
              Some highlights of our favorite projects we've done for forward thinking clients.
            </p>
            {/* <p>
        Start reading below or{" "}
        <a href="https://gumroad.com/blog/rss.xml" className="link-hover">
          grab the RSS
        </a>
        .
      </p> */}
          </div>
        </div>
        <div
          className="floater-wrap blog-6"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study1}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
        <div
          className="floater-wrap blog-5"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study2}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
        <div
          className="floater-wrap blog-4"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study3}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
        <div
          className="floater-wrap blog-3"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study3}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
        <div
          className="floater-wrap blog-2"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study4}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
        <div
          className="floater-wrap blog-1"
          style={{
            willChange: "transform",
            transform:
              "translate3d(0px, 6.1868vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d"
          }}
        >
          <img
            src={study5}
            loading="lazy"
            alt=""
            className="floater-image"
          />
        </div>
      </div>
      {/* <Testimonial/> */}
      <div
        data-w-id="4bae5684-8429-67b2-8e14-e1ef2d960142"
        className="section small wf-section"
      >
       
        <div className="container">
          <div className="w-dyn-list">
            <div role="list" className="blog-grid w-dyn-items">
            {arr && arr.map((item) => {
                return (
                  <>
             
                    <div role="listitem" className="flex w-dyn-item">
                      <a
                      href={item.url}
                        className="card-wrap w-inline-block"
                      >
                        <div className="card-thumbnail-wrap">
                          <img
                            src={item.img}
                            loading="lazy"
                            alt="Behind Gumroad’s New Design System"
                            className="card-thumbnail"
                          />
                        </div>
                        <div className="card-content blog">
                          <div className="text-small">{item.date}</div>
                          <h4 className='title-head-h4'>{item.title}</h4>
                          <div>
                          {item.content}
                          </div>
                          <div>
                            <strong>Read More -&gt;</strong>
                          </div>
                        </div>
                      </a>
                    </div>
                    </>
        );
      })}
            </div>
          </div>
        </div>
       
      </div>
      <SubFooter />
      <Footer />


    </div>
  )
}

export default CaseStudy